<template>
  <div class="mail-detail" v-if="detail">
    <div class="mail-detail-base">
      <div class="title-box">
        <div class="mail-main-title">
          <span class="title" v-html="detail.baseInfo.title_deal"></span>
          <span class="order-num" v-if="detail.baseInfo.businessJson">
            <!-- 询盘号 -->
            {{ $t('other.inquiryNumber') }}
            {{ detail.baseInfo.businessJson }}
            <i class="icon-close el-icon-close" @click="removeOrderNum"></i>
          </span>
          <div class="label-item" v-for="(label, index) in detail.mailLabelConfigVOS" :key="label.mailLabelConfigId"
            :style="{ 'background-color': label.labelColor }">
            <span class="label">{{ label.labelName }}</span>
            <i v-if="$route.query.authStatus != '2'" class="el-icon-close remove-icon"
              @click="removeLabel(label, index)"></i>
          </div>
        </div>
        <!-- 失效邮件,发送中，发送失败不展示 -->
        <div class="operate-btns" v-if="
            $route.query.authStatus != 2 &&
            ![0, 2, 3].includes(detail.baseInfo.sendFlag)
          ">
          <template>
            <el-tooltip :content="$t('mailConfig.assign')" placement="top" v-if="!noAssignDir && isGmail && showAssign">
              <el-button type="text" size="mini" @click="distributeFn">
                <i class="iconfont icon-fenpei1"></i>
              </el-button>
            </el-tooltip>
          </template>
          <template v-if="
              type == 2 &&
              detail.baseInfo.timingSwitch &&
              detail.baseInfo.timingType == 0
            ">
            <el-tooltip :content="$t('iconbtn.Modificationtime')" placement="top">
              <el-button type="primary" plain size="mini" icon="el-icon-alarm-clock" @click="
                  $refs.SendTimeModel.showDialog(detail.baseInfo.timingSendTime)
                "></el-button>
            </el-tooltip>
          </template>
          <template v-if="type == 2 && detail.baseInfo.timingSwitch">
            <el-tooltip :content="$t('other.cancelsending')" placement="top">
              <el-button type="primary" plain size="mini" class="ml0" icon="el-icon-close"
                @click="cancelSend"></el-button>
            </el-tooltip>
          </template>
          <template v-else-if="type == 2 && !detail.baseInfo.timingSwitch">
            <!--编辑-->
            <el-tooltip :content="$t('iconbtn.edit')" placement="top">
              <el-button type="primary" plain size="mini" icon="el-icon-edit" @click="handleEdit"></el-button>
            </el-tooltip>
          </template>
          <template v-else>
            <el-tooltip :content="$t('myMail.Reply')" placement="top" v-if="showReply">
              <el-button type="primary" plain size="mini" icon="iconfont icon-huifu"
                @click="handleRepeat(false)"></el-button>
            </el-tooltip>

            <el-dropdown trigger="click">
              <el-button type="primary" plain size="mini" icon="el-icon-caret-bottom"></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="handleRepeat(false, true)" v-if="showReply">
                  <!-- 回复所有人 -->
                  {{ $t('myMail.ReplyToAll') }}
                </el-dropdown-item>
                <el-dropdown-item @click.native="handleRepeat(true)" v-if="showReply">
                  <!-- 带附件回复 2.10.0版本带附件回复文本改成回复功能不变-->
                  {{ $t('myMail.Reply') }}
                </el-dropdown-item>
                <el-dropdown-item @click.native="handleTransform" v-if="showForward">
                  <!-- 转发 -->
                  {{ $t('myMail.Repost') }}
                </el-dropdown-item>

                <el-dropdown-item @click.native="handleEditRemark">
                  <!-- 我的备注 -->
                  {{ $t('myMail.MyRemarks') }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </div>
        <!-- 重新发送 -->
        <div class="operate-btns" v-if="[0, 2, 3].includes(detail.baseInfo.sendFlag)">
          <el-tooltip :content="$t('myMail.resend')" placement="top">
            <el-button type="text" size="mini" @click="resendMail">
              <i class="iconfont icon-Resend" v-if="detail.baseInfo.sendFlag === 2 || detail.baseInfo.sendFlag === 3"></i>
            </el-button>
          </el-tooltip>
          <span v-if="detail.baseInfo.sendFlag === 0 " class="green">
            {{ $t('myMail.mailBeingDelivered') }}
          </span>
        </div>
      </div>
      <div class="info-wrapper">
        <div class="info-main" v-if="infoDetailed">
          <dl class="base-item">
            <!-- <dt>发件人</dt> -->
            <dt>{{ $t('myMail.Sender') }}</dt>
            <dd v-if="detail.mailHeader && detail.mailHeader.originFrom">
              <span class="weight">{{ detail.mailHeader.originFrom }}</span>
            </dd>
            <dd v-else>
              <span class="weight">
                {{ detail.baseInfo.sendEmail.split('@')[0] }}
              </span>
              <span v-text="`<${detail.baseInfo.sendEmail}>`"></span>
            </dd>
          </dl>
          <dl class="base-item">
            <!-- <dt>时间</dt> -->
            <dt>{{ $t('myMail.Time') }}</dt>
            <dd>
              <span class="weight" v-if="lang != 'en'">
                {{
                detail.baseInfo.timingSwitch
                ? utils.formatTime(
                detail.baseInfo.timingSendTime,
                'mail-info'
                )
                : utils.formatTime(detail.baseInfo.sendTime, 'mail-info')
                }}
              </span>
              <span v-else class="weight">
                {{
                detail.baseInfo.timingSwitch
                ? utils.formatTimeToEnglishDate(
                detail.baseInfo.timingSendTime
                )
                : utils.formatTimeToEnglishDate(detail.baseInfo.sendTime)
                }}
              </span>
            </dd>
          </dl>
          <dl class="base-item" v-if="detail.extendInfo && detail.extendInfo.receiveEmails">
            <dt>
              <!-- 收件人 -->
              {{ $t('myMail.Recipient') }}
            </dt>
            <dd v-if="detail.mailHeader && detail.mailHeader.originTo">
              <span v-for="(v, index) in detail.mailHeader.originTo.split(',')" :key="index">
                <span class="weight">{{ v + ';' }}</span>
              </span>
            </dd>
            <dd v-else>
              <span v-for="(v, index) in detail.extendInfo.receiveEmails.split(',')" :key="index">
                <span class="weight">{{ v.split('@')[0] }}</span>
                <span v-text="`<${v}>;`"></span>
              </span>
            </dd>
          </dl>
          <dl class="base-item" v-if="
              'mailHeader' in detail &&
              detail.mailHeader &&
              detail.mailHeader.originCc
            ">
            <dt>
              <!-- 抄送人 -->
              {{ $t('other.CCperson') }}
            </dt>

            <dd>
              <span v-for="(v, index) in detail.mailHeader.originCc.split(',')" :key="index">
                <span class="weight">{{ v + ';' }}</span>
              </span>
            </dd>
          </dl>
          <dl class="base-item" v-if="
              detail.extendInfo &&
              detail.extendInfo.ccEamils &&
              !('mailHeader' in detail)
            ">
            <dt>
              <!-- 抄送人 -->
              {{ $t('other.CCperson') }}
            </dt>

            <dd v-if="detail.extendInfo.ccEamils">
              <span v-for="(v, index) in detail.extendInfo.ccEamils.split(',')" :key="index">
                <span class="weight">{{ v.split('@')[0] }}</span>
                <span v-text="`<${v}>;`"></span>
              </span>
            </dd>
          </dl>

          <dl class="base-item" v-if="
              'mailHeader' in detail &&
              detail.mailHeader &&
              detail.mailHeader.originBcc
            ">
            <dt>
              <!-- 密送人 -->
              {{ $t('other.CC') }}
            </dt>
            <dd>
              <span v-for="(v, index) in detail.mailHeader.originBcc.split(',')" :key="index">
                <span class="weight">{{ v + ';' }}</span>
              </span>
            </dd>
          </dl>

          <dl class="base-item" v-if="
              detail.extendInfo &&
              detail.extendInfo.bccEmails &&
              !('mailHeader' in detail)
            ">
            <dt>
              <!-- 密送人 -->
              {{ $t('other.CC') }}
            </dt>
            <dd v-if="detail.extendInfo.bccEmails">
              <span v-for="(v, index) in detail.extendInfo.bccEmails.split(',')" :key="index">
                <span class="weight">{{ v.split('@')[0] }}</span>
                <span v-text="`<${v}>;`"></span>
              </span>
            </dd>
          </dl>
        </div>
        <div class="info-main" v-else>
          <div class="info-simple">
            <span v-if="detail.mailHeader && detail.mailHeader.originFrom">
              {{ detail.mailHeader.originFrom }}
            </span>
            <span v-else>
              {{ detail.baseInfo.sendEmail.split('@')[0] }}
              {{ '&lt;' + detail.baseInfo.sendEmail + '&gt;' }}
            </span>
            <span class="desc">
              <!-- 于 -->
              {{ $t('other.At') }}
              <span v-if="lang != 'en'">
                {{
                detail.baseInfo.timingSwitch
                ? utils.formatTime(
                detail.baseInfo.timingSendTime,
                'mail-info'
                )
                : utils.formatTime(detail.baseInfo.sendTime, 'mail-info')
                }}
              </span>
              <span v-else>
                {{
                detail.baseInfo.timingSwitch
                ? utils.formatTimeToEnglishDate(
                detail.baseInfo.timingSendTime
                )
                : utils.formatTimeToEnglishDate(detail.baseInfo.sendTime)
                }}
              </span>

              <!-- 发送给 -->
              {{ $t('other.sendto') }}
            </span>
            <template v-if="detail.mailHeader && detail.mailHeader.originTo">
              <span v-for="(v, index) in detail.mailHeader.originTo.split(',')" :key="index">
                <span class="weight">{{ v + ';' }}</span>
              </span>
            </template>
            <template v-if="
                (!detail.mailHeader || !detail.mailHeader.originTo) &&
                detail.extendInfo.receiveEmails
              ">
              <span v-for="(v, index) in detail.extendInfo.receiveEmails.split(',')" :key="index">
                <span class="weight">{{ v.split('@')[0] }}</span>
                <span v-text="`<${v}>;`"></span>
              </span>
            </template>
          </div>
        </div>
        <span class="btn-key" @click="infoDetailed = !infoDetailed">
          <!-- {{ infoDetailed ? '精简信息>>' : '详细信息>>' }} -->
          {{
          infoDetailed
          ? $t('other.Condensedinformation')
          : $t('other.details')
          }}
        </span>
      </div>
      <div class="sage-order" v-if="enquiryInfo.enquiryId">
        <template v-if="enquiryInfo.salesOrderNumber">
          识别为 SAGE 平台订单，已生成订单：<span @click="goToOrder(enquiryInfo)">{{ enquiryInfo.salesOrderNumber }}</span>
        </template>
        <template v-else>
          识别为 SAGE 平台询盘，<span @click="goToOrder(enquiryInfo)">点此创建订单</span>。
          <el-tooltip placement="top" effect="dark" content="点击后，自动将客户在SAGE平台填写的信息填充到订单上">
            <i class="el-icon-warning-outline" />
          </el-tooltip>
        </template>
      </div>
      <div class="myremark" v-if="remarkFlag">
        <div class="remark-text" v-if="!remarkEditFlag">
          <span class="weight">{{ detail.baseInfo.emailMemo }}</span>
          <el-button class="remark-text-btn ml-10" type="text" icon="el-icon-edit" size="mini"
            @click="handleEditRemark"></el-button>
          <el-button class="remark-text-btn ml-5" type="text" icon="el-icon-delete" size="mini"
            @click="removeRemark"></el-button>
        </div>
        <div v-else class="remark-form">
          <el-input ref="myRemark" v-model="myRemark" maxlength="500" show-word-limit size="mini" class="input"
            @blur="remarkBlur"></el-input>
          <el-button class="ml-5" size="mini" type="text" @click="updateRemark">
            <!-- 保存 -->
            {{ $t('cusManage.Save') }}
          </el-button>
          <el-button class="ml-5" size="mini" type="text" @click="cancelRemarkEdit">
            <!-- 取消 -->
            {{ $t('cusDetail.Cancel') }}
          </el-button>
        </div>
      </div>
    </div>
    <!-- 邮件详情容器 -->
    <div class="mail-iframe-wrapper">
      <div class="mail-iframe-div"></div>
      <iframe class="mail-iframe" height="500" style="border: none" src="./mailinfo.html"></iframe>
    </div>

    <div class="mail-deatil-bottom">
      <div class="mail-detail-attach">
        <!-- 邮件附件 -->
        <MailFiles ref="mailFiles" :show-close="false" />
      </div>
      <div class="mail-detail-btns" v-if="type != 2 && showReply">
        <div v-if="type != 2" class="mb10">
          <!-- 快速回复 -->
          <el-input type="textarea" :placeholder="`${$t('other.Replyquicklyto')}“${
              detail.baseInfo.sendEmail
            }”`" v-model="quickReplyInfo"></el-input>
        </div>
        <el-button type="text" @click="handleRepeat(false)" :disabled="[0, 2, 3].includes(detail.baseInfo.sendFlag)">
          <!-- 切换到完整写信模式 -->
          {{ $t('myMail.SwitchToFullWritingMode') }}
        </el-button>
        <el-button type="primary" @click="quickReply" :disabled="
            !quickReplyInfo ||
            !contentAttachmentsHandled ||
            [0, 2, 3].includes(detail.baseInfo.sendFlag)
          ">
          <!-- 发送 -->
          {{ $t('myMail.Send') }}
        </el-button>
      </div>
    </div>

    <!-- 查看编辑抽屉 -->
    <!-- title="新增客户" -->
    <el-drawer :title="$t('cusManage.AddCustomer')" :visible.sync="addContactVisible" :before-close="closeClick"
      :wrapperClosable="false" size="400px">
      <ContactAdd :config="addContactConfig" ref="ContactAdd" @closeClick="closeClick" />
    </el-drawer>
    <InquiryAddModal ref="InquiryAddModal" @generateInquire="generateInquire" />
    <AddOffer ref="addOffer" id="mailOffer" />
    <!-- title="关联询盘单" -->
    <el-dialog :title="$t('myMail.AssociatedAnInquiry')" width="500px" :visible.sync="inquiry.visible"
      :close-on-click-modal="false" :before-close="inquiry.close">
      <el-form label-position="top" ref="inquiryForm" :model="inquiry.form" :rules="inquiry.rules">
        <!-- <el-form-item label="输入询盘单号" prop="order"> -->
        <el-form-item :label="$t('myMail.EnterTheInquiryNumber')" prop="order">
          <el-input v-model="inquiry.form.order" :placeholder="$t('placeholder.plsInput')" maxlength="50"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <!-- <el-button type="default" @click="inquiry.cancel">取消</el-button>
        <el-button type="primary" @click="inquiry.submit">确定</el-button> -->
        <el-button type="default" @click="inquiry.cancel">
          {{ $t('cusDetail.Cancel') }}
        </el-button>
        <el-button type="primary" @click="inquiry.submit">
          {{ $t('myMail.Confirm') }}
        </el-button>
      </span>
    </el-dialog>
    <SendTimeModel ref="SendTimeModel" @confirm="sendTimingAction" />
    <AssignmentAndTransfer :assignmentAndTransferBol.sync="assignmentAndTransferBol" :mailServer="$route.query.serve"
      :mailIdArr="mailIdArr" :title="$t('mailConfig.assign')" @saveFn="saveFn" ref="assignmentAndTransfer">
    </AssignmentAndTransfer>
  </div>
</template>

<script>
  import {
    sendMail,
    getDetailByOrderCode,
    updateDraft,
    getMailDetailById,
    deleteMailMemo,
    deleteMailInquiry,
    deleteMailLabelConfig,
    delayCancellation,
  } from '@/api/mail/mail.js'
  import { getDetailForEmail } from '@/api/mail/contacts.js'
  import ContactAdd from './ContactAdd.vue'
  import InquiryAddModal from './InquiryAddModal.vue'

  import AddOffer from '@/views/order/inquiryManage/components/addOffer.vue'
  import SendTimeModel from '@/views/mail/my-mail/components/SendTimeModel.vue'
  import { mapState, mapGetters } from 'vuex'
  import { getEmailName, isInEmailName } from '../utils/mail-data'
  import AssignmentAndTransfer from '@/components/AssignmentAndTransfer/index'
  import MailFiles from '@/components/MailFiles'
  import mailMixin from '../utils/mixin'
  import {
    getEnclosureList,
    getUnprocessedContentAttachments,
    processFile,
    replaceSrcWithLoadingSrc,
  } from '../utils/file'
  import { hasRight } from '@/utils/permissionBtn'
  import { addTargetBlank } from 'kits'
  import { draftNames } from '../utils/mail-data'
  import { BusEmit, BusOn } from '@/utils/eventBus'
  import { MailInteractor } from '@/core'
  import {
    checksingleMarkDel,
    getUnhandledContentFiles,
    getMailInfoContent,
  } from '../utils/tool'

  export default {
    name: 'MailInfo',
    mixins: [mailMixin, BusOn],
    components: {
      ContactAdd,
      InquiryAddModal,
      AddOffer,
      SendTimeModel,
      AssignmentAndTransfer,
      MailFiles, //邮件附件
    },
    computed: {
      isSend() {
        return getEmailName(this.$route.query.mailName)?.type == 1
      },
      ...mapState({
        userInfo: (state) => state.user.userInfo,
      }),
      ...mapGetters({
        lang: 'settings/language',
      }),
      type() {
        // 0：收件箱，1已发送，2草稿，3垃圾箱，4通讯录  0:已删除
        // 其他文件夹里面的邮件默认为收件箱
        // return getEmailName(this.$route.query.mailName).type
        if (isInEmailName(this.detail.baseInfo.boxName)) {
          return getEmailName(this.detail.baseInfo.boxName).type
        } else {
          return 0
        }
      },
      contentAttachmentsHandled() {
        return this.unhandledContentFileCounts == 0
      },
    },
    data() {
      const minHeight = () => {
        const H = document.body.clientHeight
        return H - 428
      }
      return {
        minHeight,
        infoDetailed: false, //是否显示详细信息
        addContactVisible: false,
        addContactConfig: null,
        detail: null,
        quickReplyInfo: '',
        remarkEditFlag: false,
        remarkFlag: false,
        myRemark: '',
        inquiry: {
          visible: false,
          form: { order: '' },
          rules: {
            order: [{ required: true, message: '请输入', trigger: 'blur' }],
          },
          close: () => {
            this.$refs.inquiryForm.resetFields()
            this.inquiry.visible = false
          },
          cancel: () => {
            this.$refs.inquiryForm.resetFields()
            this.inquiry.visible = false
          },
          submit: () => {
            getDetailByOrderCode(this.inquiry.form.order).then((res) => {
              if (res.code == '000000') {
                if (res.data) {
                  this.$store
                    .dispatch('mail/setMailTag', {
                      mailId: this.detail.baseInfo.mailId,
                      businessJson: this.inquiry.form.order,
                    })
                    .then((res) => {
                      this.$message({
                        type: 'success',
                        // message: '询盘单号更新成功！',
                        message: this.$t('reqmsg.$38'),
                      })
                      this.detail.baseInfo.businessJson =
                        this.inquiry.form.order
                      this.inquiry.form = { order: '' }
                      this.inquiry.visible = false
                    })
                    .catch((err) => { })
                } else {
                  this.$message({
                    type: 'warning',
                    // message: '当前询盘单不存在',
                    message: this.$t('reqmsg.$39'),
                  })
                }
              }
            })
          },
        },
        assignmentAndTransferBol: false,
        mailIdArr: [], //邮件id
        unhandledContentFileCounts: 0, //正文中未处理的附件
        enquiryInfo: {}, // 询盘信息
      }
    },

    methods: {
      // 删除所有标签
      delLabelAll(mailIds) {
        if (mailIds.includes(this.detail.baseInfo.mailId)) {
          this.detail.mailLabelConfigVOS = []
        }
      },
      batchDeleteLabel(deleteMarks) {
        let labelNameList = deleteMarks.map((item) => item.labelName)
        let mailLabelConfigVOS = this.detail?.mailLabelConfigVOS
        labelNameList.forEach((labelName) => {
          if (Array.isArray(mailLabelConfigVOS)) {
            let index = mailLabelConfigVOS.findIndex(
              (s) => s.labelName == labelName
            )
            if (index > -1) {
              mailLabelConfigVOS.splice(index, 1)
            }
          }
        })
      },
      // 删除标签
      delLabel(mailLabelConfigId) {
        let mailLabelConfigVOS = this.detail?.mailLabelConfigVOS
        if (Array.isArray(mailLabelConfigVOS)) {
          let index = mailLabelConfigVOS.findIndex(
            (s) => s.mailLabelConfigId == mailLabelConfigId
          )
          if (index > -1) {
            mailLabelConfigVOS.splice(index, 1)
          }
        }
      },
      // 添加标签
      addMailInfoMark(data) {
        // 判断是否操作了邮件详情标签
        if (data?.mailLabelConfigMap[this.detail?.baseInfo?.mailId]) {
          let label = {
            labelColor: data.labelColor,
            labelName: data.labelName,
            mailLabelConfigId:
              data?.mailLabelConfigMap &&
              data?.mailLabelConfigMap[this.detail?.baseInfo?.mailId],
          }
          let mailLabelConfigVOS = this.detail?.mailLabelConfigVOS
          if (
            !mailLabelConfigVOS?.find((s) => s.labelName === data.labelName)
          ) {
            // 避免重复添加
            if (!mailLabelConfigVOS) {
              this.$set(this.detail, 'mailLabelConfigVOS', [label])
            } else {
              mailLabelConfigVOS.push(label)
            }
          }
        }
      },
      // 点击分配
      distributeFn() {
        this.mailIdArr = [this.detail?.baseInfo?.mailId]
        this.assignmentAndTransferBol = true
      },
      // 分配保存
      saveFn(obj) {
        this.$refs.assignmentAndTransfer.closeLoadingFn()
        this.assignmentAndTransferBol = false
        // 分配成功后获取数量
        BusEmit('MailList', 'getReadAndUnreadCounts')
        BusEmit('MailLeftAside', 'refreshData')
        BusEmit('MailList', 'getPageList')
      },
      // 新增询盘单
      handleAddXPOrder() {
        if (this.detail.baseInfo.businessJson) {
          return this.$message({
            type: 'warning',
            // message: '当前邮件已关联询盘单号！',
            message: this.$t('reqmsg.$42'),
          })
        }
        let url = `${window.location.origin
          }/#/order/inquiryManage/inquiryAdd?from=${this.$route.name
          }&sendEmail=${encodeURI(this.detail.baseInfo.sendEmail)}&mailId=${this.detail.baseInfo.mailId
          }`
        window.open(url, '_blank')
      },
      //删除询盘单号
      removeOrderNum() {
        this.$confirm(this.$t('reqmsg.$12'), this.$t('reqmsg.M2005'), {
          type: 'warning',
        }).then(() => {
          deleteMailInquiry({ mailId: this.detail.baseInfo.mailId })
            .then((res) => {
              if (res.code === '000000') {
                this.$message({
                  type: 'success',

                  message: this.$t('reqmsg.M2008'),
                })
                this.$emit('updatePage')
                this.detail.baseInfo.businessJson = ''
                this.remarkFlag = false
                this.remarkEditFlag = false
              }
            })
            .catch((err) => { })
        })
      },
      //删除标签
      removeLabel(label, index) {
        // 校验账号是否失效
        if (!checksingleMarkDel(this.detail.baseInfo.emailAccount, this)) {
          return
        }
        this.$confirm(this.$t('reqmsg.$12'), this.$t('reqmsg.M2005'), {
          type: 'warning',
        }).then(() => {
          let [mailLabelConfigId, mailId] = [
            label.mailLabelConfigId,
            this.detail.baseInfo.mailId,
          ]
          deleteMailLabelConfig({
            mailLabelConfigId,
            mailId,
          }).then((res) => {
            if (res.code == '000000') {
              this.$t('reqmsg.M2008')
              BusEmit('MailList', 'afterRemoveLabel', {
                mailId,
                mailLabelConfigId,
              })
              this.detail.mailLabelConfigVOS.splice(index, 1)
            }
          })
        })
      },

      // 处理正文中未处理的附件
      async handleContentAttachments(unprocessedContentAttachments) {
        // 获取正文中未处理的附件
        unprocessedContentAttachments = getUnprocessedContentAttachments(
          unprocessedContentAttachments
        )
        this.unhandledContentFileCounts = unprocessedContentAttachments.length
        if (unprocessedContentAttachments.length > 0) {
          for (let i = 0; i < unprocessedContentAttachments.length; i++) {
            let attach = unprocessedContentAttachments[i]
            processFile(attach, this, 3, true, '', this)
          }
        }
      },
      // 展示详情
      async show(data) {
        this.getOrderEnquiryVO(data.mailDoc)
        // 获取详情后更改列表页发件状态
        let { mailId, sendFlag } = data?.baseInfo || {}
        BusEmit('MailList', 'changeMailSendFlag', {
          mailId,
          sendFlag,
        })
        this.mailSended = false
        this.remarkEditFlag = false
        this.remarkFlag = false
        this.myRemark = ''
        this.quickReplyInfo = ''
        // 附件处理
        data.enclosureList = getEnclosureList(
          data.enclosureList || [],
          data.unprocessedAttachments
        )
        if (this.$refs.mailFiles) {
          this.$refs.mailFiles.showFiles(data.enclosureList)
        } else {
          setTimeout(() => {
            if (this.$refs.mailFiles) {
              this.$refs.mailFiles.showFiles(data.enclosureList)
            } else {
              setTimeout(() => {
                this.$refs.mailFiles?.showFiles(data.enclosureList)
              })
            }
          }, 1000)
        }

        // 处理消息提醒点击进入时 没有显示邮件标题开始
        if (this.$route.query.notify == 1 && data?.useNotify !== false) {
          if (data?.baseInfo?.title) {
            data.baseInfo.title_deal = data.baseInfo.title
          }
        }

        //处理消息提醒点击进入时 没有显示邮件标题结束
        this.detail = data
        BusEmit('MailList', 'resetMailItemContet', {
          content: data?.extendInfo?.content,
          mailId: data?.baseInfo.mailId,
        })
        let iframe = document.querySelector('.mail-iframe')
        if (!iframe) {
          await new Promise((res) => {
            setTimeout(res, 1800)
          })
          iframe = document.querySelector('.mail-iframe')
          if (!iframe) {
            await new Promise((res) => {
              setTimeout(res, 1000)
            })
          }
          iframe = document.querySelector('.mail-iframe')
        }
        let iframedoc =
          iframe?.contentDocument || iframe?.contentWindow.document
        let body = iframedoc?.getElementsByTagName('body')?.[0]
        // 链接处理
        let content = addTargetBlank(this.detail.extendInfo.content)
        let onlyBase64 = !data?.unprocessedAttachments ||data?.unprocessedAttachments?.length === 0
        let _unhandledContentFiles = []
        if (content) {
          _unhandledContentFiles = getUnhandledContentFiles(content,onlyBase64)
        }
        //附件资源处理
        this.detail.extendInfo.content = replaceSrcWithLoadingSrc(
          content,
          (data?.unprocessedAttachments || []).concat(_unhandledContentFiles),
          onlyBase64
        )

        if (body) body.innerHTML = this.detail.extendInfo.content
        iframe && iframe.scrollIntoView()
        // 处理正文附件
        this.handleContentAttachments(
          (data.unprocessedAttachments || []).concat(_unhandledContentFiles)
        )
        this.infoDetailed = false
        if (data.baseInfo.emailMemo && data.baseInfo.emailMemo.trim()) {
          this.remarkFlag = true
        }
        let hasDraft = false
        if (draftNames.includes(data.baseInfo.boxFullName)) {
          hasDraft = true
        }
        let privilegeMailId = data?.baseInfo?.mailId
        // 判断相关操作权限
        if (
          this.$route.query.notify == 1 &&
          this.$route.query.mailId &&
          data?.useNotify !== false
        ) {
          // 针对消息跳转作特殊处理
          privilegeMailId = this.$route.query.mailId
        }
        if (hasDraft) {
          this.showAssign = false
          this.showForward = false
          this.showReply = false
        } else {
          let privilege = await this.getPrivilege(
            [privilegeMailId],
            data.baseInfo?.emailAccount || this.$route.query.account
          )
          this.showAssign = privilege?.allowAssign && hasRight('Mail:Assign')
          this.showForward = privilege?.allowForward
          this.showReply = privilege?.allowReply
        }
      },

      // 弹出添加客户信息弹窗
      handleAddContact() {
        getDetailForEmail({ email: this.detail.baseInfo.sendEmail }).then(
          (res) => {
            if (res.code === '000000') {
              if (res.data) {
                this.addContactConfig = res.data
                this.addContactVisible = true
                this.$nextTick(function () {
                  this.$refs.ContactAdd.showInfo(this.detail)
                })
              } else {
                this.addContactConfig = null
                this.addContactVisible = true
                this.$nextTick(function () {
                  this.$refs.ContactAdd.showInfo(this.detail)
                })
              }
            }
          }
        )
      },

      // 关闭新增客户弹窗
      closeClick() {
        this.addContactVisible = false
      },

      // 查询当前客户信息
      handleSearchContact() {
        this.$router.push({
          path: '/mail/my-mail/book',
          query: {
            account: this.$store.state.mail.currentAccount,
            mail: this.detail.baseInfo.sendEmail,
          },
        })
      },
      // 保存草稿
      sendTimingAction(time) {
        //先获取邮件详情
        let sendForm = {
          bccEmailList: [], // 密送人
          ccEmailList: this.detail.extendInfo.ccEamils
            ? this.detail.extendInfo.ccEamils.split(',')
            : [], // 抄送人
          content: this.detail.extendInfo.content || '', // 邮件内容
          emailAccount: this.$store.state.mail.currentAccount, // 发件人
          enclosureList: this.detail.enclosureList || [],
          receiveList: this.detail.extendInfo.receiveEmails
            ? this.detail.extendInfo.receiveEmails.split(',')
            : [], // 收件人
          title: this.detail.baseInfo.title, // 主题
          businessJson: this.detail.baseInfo.businessJson,
          singleDisplay: 0,
          timingSwitch: 1,
          timingSendTime: time,
          mailId: this.detail.baseInfo.mailId,
        }
        let data = { ...sendForm }
        updateDraft(data).then((res) => {
          if (res.code == '000000') {
            setTimeout(() => {
              this.$message({
                type: 'success',
                // message: '修改成功',
                message: this.$t('reqmsg.M2017'),
              })
              BusEmit('MailList', 'updatePage')
              BusEmit('MailList', 'getDetail', {
                mailId: this.detail.baseInfo.mailId,
              })
            }, 2500)
          }
        })
      },
      // 编辑
      handleEdit() {
        let url = `${window.location.origin}/#/write?from=draft&mailId=${this.detail.baseInfo.mailId}`

        this.utils.openWindow(url)
      },
      // 回复
      handleRepeat(flag = false, isReplyAll = false) {
        let url = `${window.location.origin}/#/write?from=reply&mailId=${this.detail.baseInfo.mailId}&file=${flag}&isReplyAll=${isReplyAll}&type=1`

        this.utils.openWindow(url)
      },
      // 取消定时发送
      cancelSend() {
        this.$confirm(this.$t('reqmsg.$52'), this.$t('reqmsg.M2005'), {
          type: 'warning',
        }).then(() => {
          delayCancellation({ mailIds: this.detail.baseInfo.mailId })
            .then((res) => {
              if (res.code === '000000') {
                this.$message({
                  type: 'success',

                  message: this.$t('reqmsg.$40'),
                })
                setTimeout(() => {
                  BusEmit('MailList', 'updatePage')
                  BusEmit('MailList', 'getDetail', {
                    mailId: this.detail.baseInfo.mailId,
                  })
                  this.remarkFlag = false
                  this.remarkEditFlag = false
                }, 2500)
              }
            })
            .catch((err) => { })
        })
      },
      handleTransform() {
        let url = `${window.location.origin}/#/write?from=transform&mailId=${this.detail.baseInfo.mailId}&type=2`
        this.utils.openWindow(url)
      },
      getMailStr(str) {
        if (str) {
          let reStr = str
            .split(',')
            .map((ele) => {
              return `${ele.split('@')[0]}&lt;${ele}&gt;`
            })
            .join(';')
          return reStr
        } else {
          return '--'
        }
      },

      // 快速回复
      async quickReply() {
        let ccStr = this.detail.extendInfo.ccEmails
          ? `<p style="font-size:12px;color:#666;"><strong>Cc:</strong>${this.getMailStr(
            this.detail.extendInfo.ccEmails
          )}</p><hr/>`
          : ''
        let xpStr = this.detail.baseInfo.businessJson
          ? `<p style="font-size:12px;color:#666;"><strong>Inquiry Number:</strong>${this.detail.baseInfo.businessJson || '--'
          }</p>`
          : ''
        let str = `<p style="font-size:12px;color:#666;">${this.quickReplyInfo
          }</p><br/><br/><hr/><p style="font-size:12px;color:#666;"><strong>From: </strong>${this.detail.baseInfo.sendEmail
          }</p>
        <p style="font-size:12px;color:#666;"><strong>Sent: </strong>${this.utils.formatTimeToEnglishDate(
            this.detail.baseInfo.sendTime,
            'mail-info'
          )}</p>
        <p style="font-size:12px;color:#666;"><strong>To: </strong>${this.getMailStr(
            this.detail.extendInfo.receiveEmails
          )}</p>
        ${ccStr}
        <p style="font-size:12px;color:#666;"><strong>Subject: </strong>${this.detail.baseInfo.title || ''
          }</p><br/>
        ${xpStr}<hr/>`
        let detailContent = getMailInfoContent('.mail-iframe')
        let content = str + (detailContent || '')
        let ccEmailList = []
        let emailAccount = this.detail.baseInfo.emailAccount
        let res = await MailInteractor.getMailAccountConfigVOApi({
          emailAccount,
        })

        if (res?.code === '000000') {
          if (res.data?.sended === 1) {
            ccEmailList = [res.data.email]
          }
        }
        let form = {
          bccEmailList: [], // 密送人
          ccEmailList, // 抄送人
          content, // 邮件内容
          emailAccount, // 发件人
          enclosureList: [],
          receiveList: [this.detail.baseInfo.sendEmail], // 收件人
          title: this.detail.baseInfo?.title?.startsWith('Re:')
            ? `${this.detail.baseInfo.title}`
            : `Re:${this.detail.baseInfo.title || ''}`, // 主题
          businessId: this.userInfo.userId,
          type: 1,
          prevMailId: this.detail.baseInfo.mailId,
          mailServer: this.detail.baseInfo?.mailServer,
        }
        sendMail(form).then((res) => {
          if (res.code === '000000') {
            this.$store
              .dispatch('mail/setMailTag', {
                mailId: this.detail.baseInfo.mailId,
                reply: 1,
              })
              .then((res) => {
                this.quickReplyInfo = ''
                setTimeout(() => {
                  BusEmit('MailList', 'updatePage')
                  BusEmit('MailLeftAside', 'refreshData') // 触发更新邮件分类数量
                  this.$message({
                    type: 'success',
                    message: this.$t('reqmsg.$41'),
                  })
                  BusEmit('MailList', 'getReadAndUnreadCounts')
                  let value = document.querySelector(
                    '#mailActionBarSearchInput'
                  )?.value
                  if (value?.trim()) {
                    document.querySelector('#mailActionBarSearchBtn')?.click()
                  }
                }, 2500)
              })
              .catch((err) => { })
          }
        })
      },

      // 下载文件
      handleDownload(fileArr) {
        fileArr.forEach((item, index) => {
          if (item.enclosureUrl.includes('http')) {
            this.download(item.enclosureUrl, item.enclosureName)
          } else {
            this.download(
              'https://weiwo-erp.oss-cn-shanghai.aliyuncs.com/' +
              item.enclosureUrl,
              item.enclosureName
            )
          }
        })
      },
      // 关联询盘单号
      relateOrder() {
        this.inquiry.form.order = this.detail.baseInfo.businessJson || ''
        this.inquiry.visible = true
      },
      // 取消备注编辑
      cancelRemarkEdit() {
        if (!this.detail.baseInfo.emailMemo) {
          this.remarkFlag = false
        }
        this.myRemark = ''
        this.remarkEditFlag = false
      },
      // 编辑备注
      handleEditRemark() {
        this.myRemark = this.detail.baseInfo.emailMemo
        this.remarkEditFlag = true
        this.remarkFlag = true
      },
      // 删除备注
      removeRemark() {
        // this.$confirm('是否确认删除？', '提示', {
        //   type: 'warning',
        // })

        this.$confirm(this.$t('reqmsg.$12'), this.$t('reqmsg.M2005'), {
          type: 'warning',
        }).then(() => {
          // this.myRemark = ' '

          deleteMailMemo({ mailId: this.detail.baseInfo.mailId })
            .then((res) => {
              if (res.code === '000000') {
                this.$message({
                  type: 'success',
                  // message: '删除成功！',
                  message: this.$t('reqmsg.M2008'),
                })
                this.$emit('updatePage')
                this.detail.baseInfo.emailMemo = this.myRemark
                this.remarkFlag = false
                this.remarkEditFlag = false
              }
            })
            .catch((err) => { })
        })
      },
      // 备注失焦点事件
      remarkBlur() {
        if (!this.myRemark) {
          // this.$confirm('未填写备注, 是否继续继续填写?', '提示', {
          //   confirmButtonText: '确定',
          //   cancelButtonText: '取消',
          //   type: 'warning',
          // })
          this.$confirm(this.$t('reqmsg.$43'), this.$t('reqmsg.M2005'), {
            confirmButtonText: this.$t('reqmsg.M2006'),
            cancelButtonText: this.$t('reqmsg.M2007'),
            type: 'warning',
          })
            .then(() => {
              this.$refs.myRemark.focus()
            })
            .catch(() => {
              this.myRemark = ''
              this.remarkEditFlag = false
              if (!this.detail.baseInfo.emailMemo) {
                this.remarkFlag = false
              }
            })
        } else if (this.myRemark.length > 500) {
          // this.$confirm('备注限制500个字符, 是否继续继续填写?', '提示', {
          //   confirmButtonText: '确定',
          //   cancelButtonText: '取消',
          //   type: 'warning',
          // })
          this.$confirm(this.$t('reqmsg.$44'), this.$t('reqmsg.M2005'), {
            confirmButtonText: this.$t('reqmsg.M2006'),
            cancelButtonText: this.$t('reqmsg.M2007'),
            type: 'warning',
          })
            .then(() => {
              this.$refs.myRemark.focus()
            })
            .catch(() => {
              this.myRemark = ''
              this.remarkEditFlag = false
              if (!this.detail.baseInfo.emailMemo) {
                this.remarkFlag = false
              }
            })
        }
      },

      // 更新备注
      updateRemark() {
        if (!this.myRemark) {
          // this.$confirm('未填写备注, 是否继续继续填写?', '提示', {
          //   confirmButtonText: '确定',
          //   cancelButtonText: '取消',
          //   type: 'warning',
          // })
          this.$confirm(this.$t('reqmsg.$43'), this.$t('reqmsg.M2005'), {
            confirmButtonText: this.$t('reqmsg.M2006'),
            cancelButtonText: this.$t('reqmsg.M2007'),
            type: 'warning',
          })
            .then(() => {
              this.$refs.myRemark.focus()
            })
            .catch(() => {
              this.myRemark = ''
              this.remarkEditFlag = false
              if (!this.detail.baseInfo.emailMemo) {
                this.remarkFlag = false
              }
            })
        } else if (this.myRemark.length > 500) {
          // this.$confirm('备注限制500个字符, 是否继续继续填写?', '提示', {
          //   confirmButtonText: '确定',
          //   cancelButtonText: '取消',
          //   type: 'warning',
          // })
          this.$confirm(this.$t('reqmsg.$44'), this.$t('reqmsg.M2005'), {
            confirmButtonText: this.$t('reqmsg.M2006'),
            cancelButtonText: this.$t('reqmsg.M2007'),
            type: 'warning',
          })
            .then(() => {
              this.$refs.myRemark.focus()
            })
            .catch(() => {
              this.myRemark = ''
              this.remarkEditFlag = false
              if (!this.detail.baseInfo.emailMemo) {
                this.remarkFlag = false
              }
            })
        } else {
          this.$store
            .dispatch('mail/setMailTag', {
              mailId: this.detail.baseInfo.mailId,
              emailMemo: this.myRemark,
            })
            .then((res) => {
              if (res.code === '000000') {
                this.$message({
                  type: 'success',
                  // message: '更新成功！',
                  message: this.$t('reqmsg.$2'),
                })
                setTimeout(() => {
                  this.$emit('updatePage')
                }, 2500)
                this.detail.baseInfo.emailMemo = this.myRemark
                if (this.myRemark) {
                  this.remarkFlag = true
                  this.remarkEditFlag = false
                } else {
                  this.remarkFlag = false
                  this.remarkEditFlag = true
                }
              }
            })
            .catch((err) => { })
        }
      },

      // 转化收件人，抄送人
      getMailStr(str) {
        if (str) {
          let reStr = str
            .split(',')
            .map((ele) => {
              return `${ele.split('@')[0]}&lt;${ele}&gt;`
            })
            .join(';')
          return reStr
        } else {
          return '--'
        }
      },

      generateInquire(data) {
        if (!data.mail) {
          this.$store
            .dispatch('mail/setMailTag', {
              mailId: this.detail.baseInfo.mailId,
              businessJson: data.enquiryCode,
            })
            .then((res) => {
              this.detail.baseInfo.businessJson = data.enquiryCode
            })
            .catch((err) => { })
        } else {
          this.$store
            .dispatch('mail/setMailTag', {
              mailId: this.detail.baseInfo.mailId,
              businessJson: data.enquiryCode,
            })
            .then((res) => {
              let url = `${window.location.origin}/#/write?from=transform&mailId=${this.checkedMails[0]}&receiveMail=${data.mail}`
              this.utils.openWindow(url)
            })
            .catch((err) => { })
        }
      },

      // 删除标签重新获取详情
      rergetDetail() {
        if (this.detail?.baseInfo?.mailId) {
          BusEmit('MailList', 'getDetail', {
            mailId: this.detail.baseInfo.mailId,
          })
        }
      },
      // 重新发送邮件
      resendMail() {
        if (!this.mailSended) {
          this.mailSended = true
        } else {
          return
        }
        let mailId = this.detail.baseInfo.mailId
        MailInteractor.resendMailApi(mailId).then((res) => {
          if (res?.code === '000000') {
            // 更改邮件详情发件状态
            this.detail.baseInfo = {
              ...this.detail.baseInfo,
              sendFlag: 0,
            }
            // 发送成功后更改发件箱邮件状态
            BusEmit('MailList', 'changeMailSendFlag', {
              mailId,
              sendFlag: 0,
            })

            // 更改左侧发件箱发件状态
            BusEmit('MailLeftAside', 'refreshData')
          } else {
            this.mailSened = false
          }
        })
      },
      // 跳转至订单新增页或详情页
      goToOrder(enquiryInfo) {
        if (enquiryInfo.salesOrderNumber) {
          // 详情
          const routeData = this.$router.resolve({
            path: '/order/orderList/orderDetail',
            query: { orderCode: enquiryInfo.salesOrderNumber, noReturn: true },
          })
          window.open(routeData.href, '_blank')
        } else {
          const { customerOrderCode: customOrderCode, customerEmail: email } = enquiryInfo
          // 新增，和询盘列表跳转至订单新增页传参保持一致
          const routeData = this.$router.resolve({
            path: '/order/orderList/orderAdd',
            // query: { businessId: enquiryInfo.enquiryId, noPriceSheetCode: 1 },
            query: { customOrderCode, email, isMailInfo: 1 }
          })
          window.open(routeData.href, '_blank')
        }
      },

      // 根据docId查询是否有sage询盘订单信息
      async getOrderEnquiryVO(mailDoc) {
        if(mailDoc?.docId) {
          const { code, data } = await MailInteractor.getOrderEnquiryVOByDocIdApi(mailDoc.docId)
          if (code === '000000') {
            this.enquiryInfo = data || {}
          }
        } else {
          this.enquiryInfo = {}
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  .mail-detail {
    background: #fff;
    border-left: 1px solid #ebeef5;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .mail-detail-base {
      border-bottom: 1px solid #ebeef5;
      padding: 20px;
      box-sizing: border-box;

      .title-box {
        display: flex;
        justify-content: space-between;
        line-height: 30px;
        margin-bottom: 10px;

        .title {
          font-size: 18px;
          font-weight: bold;
          color: #303030;
          max-width: 150em;
          word-break: break-all;
        }

        .order-num {
          padding: 0 3px;
          white-space: nowrap;
          color: #1890ff;
          background: #e8f4ff;
          font-size: 14px;
          margin-left: 10px;

          .icon-close {
            color: #ccc;
          }
        }

        .label-item {
          display: inline-block;
          margin-left: 10px;
          height: 20px;
          font-size: 12px;
          color: #fff;
          padding: 0 4px;
          border-radius: 2px;
          max-width: 100%;
          line-height: 20px;

          .label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            vertical-align: middle;
          }

          .remove-icon {
            margin-left: 2px;
            cursor: pointer;
            opacity: 0;
            vertical-align: middle;
          }

          &:hover {
            .remove-icon {
              opacity: 1;
            }
          }
        }
      }

      .operate-btns {
        margin-left: 10px;
        width: 80px;
        white-space: nowrap;
        display: flex;
        justify-content: flex-end;
        height: 28px;

        .el-button {
          width: 28px;
          height: 28px;
          padding: 4px;
          font-size: 18px;
        }
      }

      .info-wrapper {
        display: flex;

        .info-main {
          flex: 1;
          width: 200px;
        }

        .info-simple {
          font-size: 14px;
          color: #333;
          line-height: 24px;
          word-break: break-all;

          .desc {
            color: #999;
          }
        }

        .btn-key {
          font-size: 14px;
          color: #999;
          line-height: 24px;
          cursor: pointer;

          &:hover {
            color: #1890ff;
          }
        }
      }
      .sage-order {
        display: flex;
        align-items: center;
        color: #F59A23;
        > span {
          color: #1990FF;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .base-item {
        display: flex;
        margin-bottom: 10px;
        line-height: 24px;

        dt {
          width: 82px;
          height: 24px;
          background: #f8fbff;
          border-radius: 3px;
          text-align: center;
          line-height: 24px;
          margin-right: 10px;
        }

        dd {
          flex: 1;
          width: 0;
          word-wrap: break-word;
          font-size: 14px;
          color: #777;

          .weight {
            color: #303030;
          }

          .star {
            color: #fa6400;
          }
        }
      }

      .myremark {
        background-color: #f8f8f8;
        padding: 4px 6px;
        margin-top: 6px;

        .remark-text {
          display: flex;
          align-items: flex-end;

          .weight {
            font-size: 14px;
            line-height: 28px;
            word-wrap: break-word;
            flex: 1;
            width: 200px;
          }
        }

        .remark-form {
          display: flex;
          align-items: center;

          .input {
            flex: 1;
            margin-right: 10px;
          }
        }
      }
    }

    .mail-detail-html {
      padding: 40px 20px;
      box-sizing: border-box;
      border-bottom: 1px solid #ebeef5;
      flex: 1;

      overflow-x: hidden;
      overflow-y: auto;

      ::v-deep p {
        word-break: break-all;
      }

      ::v-deep img {
        max-width: 100%;
      }

      ::v-deep hr {
        display: block;
        margin: 10px 0;
        border: 0;
        width: 200px;
        border-top: 1px solid #ccc;
      }
    }

    .mail-detail-attach {
      padding: 20px 20px 0;
      box-sizing: border-box;

      &>div:nth-child(1) {
        i {
          color: #fa6400;
          font-size: 14px;
        }

        &>span {
          font-size: 14px;
          font-weight: bold;
          color: #303030;
          margin: 0 10px;
        }
      }

      &>div:nth-child(2) {
        display: flex;
        flex-wrap: wrap;

        &>div {
          padding: 0 12px;
          background: #f5f7fa;
          border-radius: 3px;
          border: 1px solid #ebeef5;
          font-size: 12px;
          margin-right: 10px;
          line-height: 30px;
          margin-bottom: 10px;
          cursor: pointer;
          width: 200px;
          display: flex;

          i {
            color: #fa6400;
            font-size: 12px;
          }

          .title {
            flex: 1;
            width: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #303030;
            line-height: 30px;
            margin-left: 10px;
          }

          .preview {
            color: #1890ff;
            margin-left: 10px;
          }

          .size {
            color: #777;
            margin-left: 10px;
          }
        }
      }
    }

    .mail-detail-btns {
      padding: 20px;
      box-sizing: border-box;
      text-align: right;
    }

    .mail-iframe-wrapper {
      flex: 1;
      height: 500px;
      position: relative;

      td {
        border: 1px solid #ddd;
      }

      .mail-iframe {
        height: 100%;
        width: 100%;
      }

      .mail-iframe-div {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1111;
        filter: alpha(opacity=0);
        opacity: 0;
        background: transparent;
        display: none;
      }
    }

    .mail-deatil-bottom {
      max-height: 230px;
    }
  }

  .gmail_attr {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 14px;
  }
</style>
