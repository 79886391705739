<template>
  <div class="pro-container">
    <el-tabs
      class="nav-tab"
      tab-position="left"
      style="height: 100%"
      @tab-click="chnageTab"
      v-model="activeTab"
    >
      <!-- 用户管理 -->
      <el-tab-pane :label="$t('mailConfig.UserManagement')" name="USER">
        <accoutSetting ref="accoutSetting" />
      </el-tab-pane>
      <!-- 配置管理 -->
      <el-tab-pane
        :label="$t('mailConfig.ConfigurationManagement')"
        name="CONFIG"
      >
        <configSetting ref="configSet" />
      </el-tab-pane>
      <!-- 账号授权 -->
      <el-tab-pane
        :label="$t('mailConfig.Accountauthorization')"
        name="ACCOUNT"
        v-if="hasRight('mailAccountAuth')"
      >
        <accountAuthorization ref="accountAuthorization" />
      </el-tab-pane>
      <!-- 中心站 -->
      <el-tab-pane
        :label="$t('mailConfig.EmailCenter')"
        name="CENTER"
        v-if="hasRight('mail:centerOperation')"
      >
        <mailCenter ref="mailCenter" />
      </el-tab-pane>
      <!-- 自动分配 -->
      <!-- <el-tab-pane :label="$t('mailConfig.automaticassignment')" name="AUTO">
        自动分配
      </el-tab-pane> -->
      <!-- 其他配置 -->
      <el-tab-pane
        :label="$t('mailConfig.otherconfigurations')"
        name="OTHER"
        v-if="hasRight('mailOtherConfigOperation')"
      >
        <otherConfig ref="otherConfig" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import accoutSetting from './accoutSetting.vue'
  import configSetting from './configSetting.vue'
  import accountAuthorization from './accountAuthorization.vue'
  import mailCenter from './mailCenter.vue'
  import otherConfig from './otherConfig.vue'
  import { hasRight } from '@/utils/permissionBtn'

  export default {
    components: {
      accoutSetting,
      configSetting,
      accountAuthorization,
      mailCenter,
      otherConfig,
    },
    data() {
      return {
        activeTab: 'USER',
      }
    },
    methods: {
      hasRight,
      chnageTab(val) {
        if (val.name === 'USER') {
          this.$refs.accoutSetting.getListDepartmrntUser()
        } else if (val.name === 'CONFIG') {
          this.$refs.configSet.getDetail()
          this.$refs.configSet.getAccountList()
        } else if (val.name === 'ACCOUNT') {
          this.$refs.accountAuthorization.getList()
          this.$refs.accountAuthorization.getDetail()
        } else if (val.name == 'CENTER') {
          // this.$refs.mailCenter.getList()
          this.$refs.mailCenter.getDetail()
        } else if (val.name == 'OTHER') {
          this.$refs.otherConfig.getDetail()
        }
      },
    },
    mounted() {
      if (this.$route.query.code) {
        this.activeTab = 'USER'
        let row = localStorage.getItem('BIND_GMAIL_FORM')
        if (row) {
          setTimeout(() => {
            this.$refs.accoutSetting.bindGmail(JSON.parse(row))
          }, 1000)
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  .nav-tab {
    ::v-deep {
      .el-tabs__content {
        height: 100%;
        overflow-y: auto !important;
      }
      .el-tabs__item {
        height: 60px !important;
        line-height: 60px !important;
        padding: 0 40px;
      }
      .is-active {
        background-color: #ebf5ff;
      }
    }
  }
</style>
